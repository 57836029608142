import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { LinksService } from '@app/core/links.service';
import { RegistrationErrorCodes } from '@app/registration/registration-error/registration-error.component';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0ErrorGuardService implements CanActivate {
  constructor(private authService: AuthService, private linksService: LinksService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { error_description } = route.queryParams;

    if (!error_description) {
      return true;
    }

    const errorDescription = JSON.parse(decodeURIComponent(error_description));
    const { errorCode } = errorDescription;
    let redirectUri: string;

    switch (errorCode) {
      case RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault:
      case RegistrationErrorCodes.DuplicateExternalId:
        const { claim_code: claimCode } = errorDescription;
        const queryParams = new URLSearchParams({
          claim_code: claimCode,
          errorCode,
        }).toString();

        redirectUri = `${this.linksService.registrationError}?${queryParams}`;
        break;
      default:
        throw new Error(`Unknown error code: ${errorCode}`);
    }

    this.authService.logout(redirectUri);
    return false;
  }
}
