import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://littlerock.app.1life.com',
  myoneServer: 'https://littlerock.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-littlerock',
  },
  launchDarklyClientId: '605016f8e13fe80c650be312',
  appSync: {
    apiUrl: 'https://okkllxxi5jebtnkki7bxeti54a.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    enabled: true,
  },
};
